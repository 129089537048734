import {default as img} from '../assets/locImgSC.jpg';

const sc = `
<div class="swiper-slide" data-swiper-slide-index="3">
	<div class="locationDiv">
		<div class="header">
			<img src="${img}" class='headerImg' />
		</div>
		<div class="locationBody">
			<div class="locationName">
				SculptureCenter
			</div>
			<div class="locationAddress">
				44-19 Purves St<br>
				Queens, NY 11101
			</div>
			<div class="locationTime">
				12pm - 6pm<br>
				Thurs - Mon
			</div>
		</div>
	</div>
</div>
`;

export {sc}