import {default as img} from '../assets/locImgCulverCity.jpg';

const culver_city = `
<div class="swiper-slide" data-swiper-slide-index="1">
	<div class="locationDiv">
		<div class="header">
			<img src="${img}" class='headerImg' />
		</div>
		<div class="locationBody">
			<div class="locationName">Jerry's Market</div>
			<div class="locationAddress">
				3969 Higuera St <br>
				Culver City, CA 90232<br>
			</div>
			<div class="locationTime">
				8am–8pm, Mon–Sat
			</div>
		</div>
	</div>
</div>
`;

export {culver_city}