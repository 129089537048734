import {default as img1} from '../assets/book/processed/SC_Jun23_095.jpg'
import {default as img2} from '../assets/book/processed/SC_Jun23_096.jpg'
import {default as img3} from '../assets/book/processed/SC_Jun23_097.jpg'
import {default as img4} from '../assets/book/processed/SC_Jun23_098.jpg'
import {default as img5} from '../assets/book/processed/SC_Jun23_099.jpg'
import {default as img6} from '../assets/book/processed/SC_Jun23_100.jpg'
import {default as img7} from '../assets/book/processed/SC_Jun23_101.jpg'
import {default as img8} from '../assets/book/processed/SC_Jun23_102.jpg'
import {default as img9} from '../assets/book/processed/SC_Jun23_103.jpg'
import {default as img10} from '../assets/book/processed/SC_Jun23_104.jpg'
import {default as img11} from '../assets/book/processed/SC_Jun23_105.jpg'
import {default as img12} from '../assets/book/processed/SC_Jun23_106.jpg'
import {default as img13} from '../assets/book/processed/SC_Jun23_107.jpg'
import {default as img14} from '../assets/book/processed/SC_Jun23_108.jpg'
import {default as img15} from '../assets/book/processed/SC_Jun23_109.jpg'
import {default as img16} from '../assets/book/processed/SC_Jun23_110.jpg'
import {default as img17} from '../assets/book/processed/SC_Jun23_111.jpg'
import {default as img18} from '../assets/book/processed/SC_Jun23_112.jpg'
import {default as img19} from '../assets/book/processed/SC_Jun23_113.jpg'
import {default as img20} from '../assets/book/processed/SC_Jun23_114.jpg'
import {default as img21} from '../assets/book/processed/SC_Jun23_116.jpg'
import {default as img22} from '../assets/book/processed/SC_Jun23_124.jpg'
import {default as img23} from '../assets/book/processed/SC_Jun23_125.jpg'
import {default as img24} from '../assets/book/processed/SC_Jun23_092.jpg'


const book = `
<div class="swiper-slide" data-swiper-slide-index="0">
	<div class="image">
		<img src="${img23}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="1">
	<div class="image">
		<img src="${img22}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="2">
	<div class="image">
		<img src="${img21}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="3">
	<div class="image">
		<img src="${img24}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="4">
	<div class="image">
		<img src="${img1}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="5">
	<div class="image">
		<img src="${img2}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="6">
	<div class="image">
		<img src="${img3}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="7">
	<div class="image">
		<img src="${img4}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="8">
	<div class="image">
		<img src="${img5}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="9">
	<div class="image">
		<img src="${img6}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="10">
	<div class="image">
		<img src="${img7}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="11">
	<div class="image">
		<img src="${img8}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="12">
	<div class="image">
		<img src="${img9}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="13">
	<div class="image">
		<img src="${img10}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="14">
	<div class="image">
		<img src="${img11}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="15">
	<div class="image">
		<img src="${img12}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="16">
	<div class="image">
		<img src="${img13}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="17">
	<div class="image">
		<img src="${img14}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="18">
	<div class="image">
		<img src="${img15}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="19">
	<div class="image">
		<img src="${img16}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="20">
	<div class="image">
		<img src="${img17}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="21">
	<div class="image">
		<img src="${img18}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="22">
	<div class="image">
		<img src="${img19}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="23">
	<div class="image">
		<img src="${img20}" class="galleryImage" />
	</div>
</div>
`;

export {book}