import {default as img} from '../assets/locImgBike2.jpg';

const bike = `
<div class="swiper-slide" data-swiper-slide-index="1">
	<div class="locationDiv">
		<div class="header">
			<img src="${img}" class='headerImg' />
		</div>
		<div class="locationBody">
			<div class="locationName">Salim's Bike</div>
			<div class="locationDetails">
				Attached to Salim’s Bike.
			</div>
		</div>
	</div>
</div>
`;

export {bike}