import {default as img} from '../assets/locImgIA.jpg';

const ia = `
<div class="swiper-slide" data-swiper-slide-index="1">
	<div class="locationDiv">
		<div class="header">
			<img src="${img}" class='headerImg' />
		</div>
		<div class="locationBody">
			<div class="locationName">Payne Memorial AME Church</div>
			<div class="locationAddress">
				1044 Mobile St<br>
				Waterloo, IA 50703
			</div>
			<div class="locationTime">
				11am–5pm Daily
			</div>
		</div>
	</div>
</div>
`;

export {ia}