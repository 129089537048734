import * as d3 from 'd3v4'
import { data } from './mis_dat.js'
import {default as asterisk} from '../assets/asterisk.svg';
import { culver_city } from './culver_city.js';
import { bike } from './bike.js';
import { about } from './about.js';
import { ia } from './ia.js';
import { stuy } from './stuy.js';
import { ct } from './ct.js';
import { install } from './install.js';
import { book } from './book.js';
import { roof_farm } from './roof_farm.js';
import { culver_city } from './culver_city.js';
import { start_end } from './start_end.js';
import { easter_in_waterloo } from './easter_in_waterloo.js';
import { nax_steels_halloween } from './nax_steels_halloween.js';
import { sc } from './sc.js';
import { pa } from './pa.js';
import {swiper} from './swiper.js'

const parser = new DOMParser();

Element.prototype.setAttributes = function (attrs) {
    for (var idx in attrs) {
        if ((idx === 'styles' || idx === 'style') && typeof attrs[idx] === 'object') {
            for (var prop in attrs[idx]){this.style[prop] = attrs[idx][prop];}
        } else if (idx === 'html') {
            this.innerHTML = attrs[idx];
        } else {
            this.setAttribute(idx, attrs[idx]);
        }
    }
};

function htmlToElement(html) {
    var template = document.createElement('template')
    html = html.trim() // Never return a text node of whitespace as the result
    template.innerHTML = html
    return template.content.firstChild
}

function mapDFT(
    data, 
    {
        cityXs=[.1, .5, .8, .9, .95],
        cityYs=[.8, .1, .4, .2, .13],
        cityIds=['culver_city', 'ia', 'pa', 'ny' ,'ct'],
        cityMarker=null,
        orbX=.1,
        orbY=.1,
        titleX=.5,
        titleY=.95,
        labels=false,
        mapPoints=asterisk,
        mapPointsWidth=25,
        mapPointsHeight=44
    } = {} ) {

    d3.select("svg").selectAll('*').remove();

    const swiperControls = d3.select('#control_panel');

    var svg = d3.select('svg')

    var about = d3.select('#about')
    var links = d3.select('#links')
    var card = d3.select('#card')
    
    var films = d3.select("#films")

    var link_dft_book_images = d3.select("#link_dft_book_images")
        .on('click', function() {
                showBookImages();
                setTimeout(()=>document.addEventListener('click', listenClick),200);
            })

    var link_dft_installation_images = d3.select("#link_dft_installation_images")
        .on('click', function() {
                showInstallImages();
                setTimeout(()=>document.addEventListener('click', listenClick),200);
            })

    var link_easter_in_waterloo = d3.select("#link_easter_in_waterloo")
        .on('click', function() {
                films.classed("hidden", false);
                films._groups[0][0].innerHTML=easter_in_waterloo;
                setTimeout(()=>document.addEventListener('click', listenClick),200);
            })
    var link_nax_steels_halloween = d3.select("#link_nax_steels_halloween")
        .on('click', function() {
                films.classed("hidden", false);
                films._groups[0][0].innerHTML=nax_steels_halloween;
                setTimeout(()=>document.addEventListener('click', listenClick),200);
            })
    var link_start_end = d3.select("#link_start_end")
            .on('click', function() {
                films.classed("hidden", false);
                films._groups[0][0].innerHTML=start_end;
                setTimeout(()=>document.addEventListener('click', listenClick),200);
            })

    var title = d3
        .select('#title_div')
        .on('click', function() {
            about.classed('hidden', false);
            setTimeout(()=>document.addEventListener('click', listenClick),200);
        })

    const svgWidth = svg.node().getBoundingClientRect().width;
    const svgHeight = svg.node().getBoundingClientRect().height;

    // update the size and position of the SVG canvas
    svg.attr("viewBox", `0 0 ${svgWidth} ${svgHeight}`);

    // one idea for click outside of a city
    // const background_rect = svg
    // .append('g')
    // .append('rect')
    // .attr('x',0)
    // .attr('y',0)
    // .attr('width',svgWidth)
    // .attr('height',svgHeight)
    // .attr('fill', 'green')
    // .on('click', function (d){
    //     console.log(clicked);
    //     if (clicked) {
    //         clicked=false;
    //         console.log('yup');
    //         hideLoc();
    //     }
    // });

    // const label = svg
    //     .append("text")
    //     .text("DARK FOREST THEORY")
    //     .attr("x", titleX*svgWidth)
    //     .attr("y", titleY*svgHeight)
    //     .style("text-anchor", "middle");


    // const label = svg
    //     .append('g')
    //     .attr('font-size', 14)
    //     .attr('text-anchor', 'end')
    //     .selectAll('g')
    //     .data(nodes)
    //     .enter()
    //     .append('g')
    //     .attr('transform', (d) => {
    //         return `translate(${margin.left},${(d.y = y(d.id))})`
    //     })
    //     .call((g) =>
    //         g
    //             .append('text')
    //             .attr('x', -12)
    //             .attr('transform-origin', '-10px 0px')
    //             .attr('dy', '0.35em')
    //             .attr('fill', (d) => d3.lab(color(d.group)).darker(2))
    //             .text((d) => d.id.toLowerCase())
    //     )
    //     .call((g) =>
    //         g
    //             .append('circle')
    //             .attr('r', (d) => Math.pow(d.count, 1 / 3.4))
    //             .attr('fill', (d) => d3.lab(color(d.group)).darker(2))
    //             //.attr('fill', (d) => color(d.group))
    //     )

    function listenClick(e){
        console.log(e);
        // console.log(document.contains(e.target));
        console.log(e.target.classList);

        if (!e.target.classList.contains('pointerBox') && 
            !e.target.classList.contains('swiper-pagination-bullet') && 
            !e.target.classList.contains('aboutclass') &&
            !e.target.classList.contains('link')) {

            hideLoc();

            films.classed('hidden', true);
            films._groups[0][0].innerHTML='';

            const els = document.querySelectorAll('.pointerBox');
            [].forEach.call(els, function(el){
                el.classList.remove('clicked');
                el.classList.remove('secondary');
            });
        }
    }

    // document.addEventListener('click', listenClick);

    const orb = svg
        .append('circle')
        .attr('class', 'orb')
        .attr('fill', '#4CC21A')
        .attr('cx', ((svgWidth*orbX) - svgHeight*.1 )> 0 ? svgWidth*orbX : svgHeight*.1)
        .attr('cy', svgHeight*orbY)
        .attr('r', svgHeight*.1)
        .on('click', function (d,e) {

            d3.select(this).attr('fill', d3.select(this).attr('fill')=='black'? '#4CC21A' : 'black');

            links.classed('hidden', links.classed('hidden') ? false : true);
            card.classed('hidden', card.classed('hidden') ? false : true);

            overlay_rects.each(function(d) {
                d3.select(this).classed('hidden', d3.select(this).classed('hidden') ? false : true);
            })

            cityPoints.each(function(d) {
                d3.select(this).classed('hidden', d3.select(this).classed('hidden') ? false : true);
            })

        });


    const cityPoints = svg
        .append('g')
        .selectAll('g')
        .data(cityXs.map((el,i)=>({x:el, y:cityYs[i]})))
        .enter()
        .append("g")
        .attr('class', 'cityPoints')

    d3.xml(asterisk, function(error, svg) {
      if (error) throw error;

      cityPoints.each(function(d) {

        d3.select(this)
          .node()
          .appendChild(svg.documentElement.cloneNode(true))
          .setAttributes({x:d.x*svgWidth-mapPointsWidth/2, y:d.y*svgHeight-mapPointsHeight/2, width:mapPointsWidth, height:mapPointsHeight, viewBox:"0 0 37 66"}); //37 is width of asterisk
      });
    });

    function showBookImages() {
        swiper.removeAllSlides();
        document.getElementById("andrewsSlider").innerHTML = book;
        swiper.updateSlides();
        d3.selectAll('.swiper').style('opacity',1);
        d3.selectAll('.swiper-pagination-bullet').classed('bigBullet', false);
        d3.selectAll('.swiper-pagination-bullet').classed('smallBullet', true);
        swiperControls.classed('hidden', false);
    }

    function showInstallImages() {
        swiper.removeAllSlides();
        document.getElementById("andrewsSlider").innerHTML = install;
        swiper.updateSlides();
        d3.selectAll('.swiper').style('opacity',1);
        d3.selectAll('.swiper-pagination-bullet').classed('bigBullet', false);
        d3.selectAll('.swiper-pagination-bullet').classed('smallBullet', true);
        swiperControls.classed('hidden', false);
    }

    function showLoc(node) {

        about.classed('hidden', true);

        swiper.removeAllSlides();

        if (node._groups[0][0].id=='culver_city') {
            swiper.appendSlide(culver_city);
            swiper.appendSlide(bike);
        }
        
        if (node._groups[0][0].id=='ia') {
            swiper.appendSlide(ia);
        }

        if (node._groups[0][0].id=='pa') {
            swiper.appendSlide(pa);
        }

        if (node._groups[0][0].id=='ny') {
            swiper.appendSlide(sc);
            swiper.appendSlide(stuy);
            swiper.appendSlide(roof_farm);
        }

        if (node._groups[0][0].id=='ct') {
            swiper.appendSlide(ct);
        }

        // switch (node._groups[0][0].id) {
        //     case 'culver_city':
        //         swiper.appendSlide(culver_city);
        //         break;
        //     case 'ai':
        //         swiper.appendSlide(ia);
        //         break;
        //     case 'pa':
        //         swiper.appendSlide(pa);
        //         break;
        //     case 'ny':
        //         swiper.appendSlide(bike);
        //         break;
        //     case 'ct':
        //         swiper.appendSlide(ct);
        //         break;
        // }
        
        d3.selectAll('.swiper').style('opacity',1);
        swiperControls.classed('hidden', false);
        d3.selectAll('.swiper-pagination-bullet').classed('bigBullet', true);
        d3.selectAll('.swiper-pagination-bullet').classed('smallBullet', false);
          
    }

    function hideLoc() {
        swiper.removeAllSlides();
        d3.selectAll('.swiper').style('opacity',0);
        swiperControls.classed('hidden', true);
        about.classed('hidden', true);
    }   

    const overlay_rects = svg
    .append('g')
    .selectAll('g')
    .data(cityXs.map((el,i)=>({x:el, y:cityYs[i], id:cityIds[i]})))
    .enter()
    .append('rect')
    .style('cursor','pointer')
    .attr('fill', 'none')
    .attr('pointer-events', 'all')
    .attr('x', d=>d.x*svgWidth-mapPointsWidth/2)
    .attr('y', d=>d.y*svgHeight-mapPointsHeight/2)
    .attr('id', d=>d.id)
    .attr('class', 'pointerBox')
    .attr('width', mapPointsWidth)
    .attr('height', mapPointsHeight)
    .on('mouseover', function (d,e) {
        document.removeEventListener('click', listenClick);
        d3.select(this).classed('primary', true);
        // cityPoints.classed('clicked', n => n.x === d.x);
        cityPoints.classed('secondary', n => n.x !== d.x);
        showLoc(d3.select(this));
        setTimeout(()=>document.addEventListener('click', listenClick),200);
    })
    .on('mouseout', function (d) {
        d3.select(this).classed('primary', false);
        // d3.select(this).classed('clicked', false)
        cityPoints.classed('secondary', false);
        // if (!svg.attr('class').includes('clicked')) {
        //     svg.classed('hover', false)
        //     cityPoints.classed('primary', false)
        //     cityPoints.classed('secondary', false)
        //     path.classed('primary', false).order()
        // }
        // if (!d3.select(this).attr('class').includes('clicked')) {
        //     hideLoc();
        // }
    })
    // .on('click', function (d) {
    //     d3.select(this).classed('primary', true);
    //     cityPoints.classed('secondary', n => n.x !== d.x);
    //     d3.select(this).classed('clicked', true);
    // })
    
    // svg
    //     .selectAll('g')
    //     .selectAll('g')
    //     .each(function(d) {
    //         //console.log(d3.select(this)._groups[0][0].childNodes[0])

    //         d3.select(this)._groups[0][0].childNodes[0].setAttribute('y',"500");
            
    //         // .attr("x",d=>{console.log('yea'); return d.x*svgWidth;})
    //         // .attr("y", d=> d.y*svgHeight);
    //     });

    return Object.assign(svg.node())
    }


function plotMap() {

    mapDFT();

    // redraw the chart when the window is resized
    d3.select(window).on("resize", mapDFT);
    
}

export { plotMap }