import {default as img} from '../assets/locImgCT.jpg';

const ct = `
<div class="swiper-slide" data-swiper-slide-index="1">
	<div class="locationDiv">
		<div class="header">
			<img src="${img}" class='headerImg' />
		</div>
		<div class="locationBody">
			<div class="locationName">
				Wesleyan University
			</div>			
			<div class="locationAddress">
				Ezra and Cecile Zilkha Gallery<br>
				283 Washington Terrace<br>
				Middletown, CT 06459 
			</div>
			<div class="locationDetails">
				Enter the first floor lobby. Open the door on the left and take the stairs down one flight into the tunnels. Follow the sounds. <br>
				Contact: <a href="mailto:bchaffee@wesleyan.edu">bchaffee@wesleyan.edu</a><br>
			</div>
			<div class="locationTime">
				By appointment, 9:30am-5:00pm, Mon - Thurs
			</div>
		</div>
	</div>
</div>
`;

export {ct}