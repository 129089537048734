import {default as img} from '../assets/locImgStuy2.jpg';

const stuy = `
<div class="swiper-slide" data-swiper-slide-index="1">
	<div class="locationDiv">
		<div class="header">
			<img src="${img}" class='headerImg' />
		</div>
		<div class="locationBody">
			<div class="locationName">Stuy Wash N Dry</div>
			<div class="locationAddress">
				411 Tompkins Avenue <br>
				Brooklyn, NY 11216
			</div>
			<div class="locationTime">
				7am–8pm daily
			</div>
		</div>
	</div>
</div>
`;

export {stuy}