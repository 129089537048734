import {default as img1} from '../assets/install/processed/SC_Jun23_046.jpg';
import {default as img2} from '../assets/install/processed/SC_Jun23_050.jpg';
import {default as img3} from '../assets/install/processed/SC_Jun23_052.jpg';
import {default as img4} from '../assets/install/processed/SC_Jun23_054.jpg';
import {default as img5} from '../assets/install/processed/SC_Jun23_062.jpg';
import {default as img6} from '../assets/install/processed/SC_Jun23_072.jpg';
import {default as img7} from '../assets/install/processed/SC_Jun23_075.jpg';
import {default as img8} from '../assets/install/processed/SC_Jun23_080.jpg';

const install = `
<div class="swiper-slide" data-swiper-slide-index="0">
	<div class="image">
		<img src="${img1}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="1">
	<div class="image">
		<img src="${img2}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="2">
	<div class="image">
		<img src="${img3}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="3">
	<div class="image">
		<img src="${img4}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="4">
	<div class="image">
		<img src="${img5}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="5">
	<div class="image">
		<img src="${img6}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="6">
	<div class="image">
		<img src="${img7}" class="galleryImage" />
	</div>
</div>
<div class="swiper-slide" data-swiper-slide-index="7">
	<div class="image">
		<img src="${img8}" class="galleryImage" />
	</div>
</div>
`;

export {install}