import {default as img} from '../assets/locImgRoofFarm.jpg';

const roof_farm = `
<div class="swiper-slide" data-swiper-slide-index="2">
	<div class="locationDiv">
		<div class="header">
			<img src="${img}" class='headerImg' />
		</div>
		<div class="locationBody">
			<div class="locationName">Eagle Street Rooftop Farm</div>
			<div class="locationAddress">
				44 Eagle Street<br>
				Brooklyn, NY 11222
			</div>
			<div class="locationTime">
				Open June 30 and July 21, 2023, 9am–12pm <br />
				Or by appointment. Contact: growingchefs@gmail.com
			</div>
		</div>
	</div>
</div>
`;

export {roof_farm}