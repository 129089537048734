import SwiperCore, { Navigation, Pagination, Manipulation, Keyboard } from 'swiper';

import "swiper/swiper-bundle.min.css";

SwiperCore.use([ Manipulation ]);

const parser = new DOMParser();

document.documentElement.style.setProperty("--swiper-theme-color", "#000")
document.documentElement.style.setProperty("--swiper-navigation-size", "5")

function htmlToElement(html) {
    var template = document.createElement('template')
    html = html.trim() // Never return a text node of whitespace as the result
    template.innerHTML = html
    return template.content.firstChild
}

// init Swiper:
const swiper = new SwiperCore('.swiper', {
  // Optional parameters
  //effect:'fade',
  centeredSlides:true,
  slidesPerView:1,
  // fadeEffect:{
  //   crossFade:true
  // },
  keyboard: {
    enabled: true,
    onlyInViewport: false,
  },
  direction: 'horizontal',
  loop: true,
  createElements:true,

  // old pagination
  // pagination: {
  //   el: '.swiper-pagination',
  //   type: 'fraction',
  //   dynamicBullets:true
  // },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  
  modules: [Navigation, Pagination, Keyboard]

});


const buildSwiper = (srcs) => {

  Array.from(new Array(data.n)).map( (it,i) => {
      document.getElementById("andrewsSlider").appendChild(parser.parseFromString(
          `<div style="margin-right:0px; width:375px;" class="swiper-slide" data-swiper-slide-index="${i+9}"><img src="http://localhost:5000/api/image/Bellyman/1" loading="lazy" /><div style="margin-right:0px" class="swiper-lazy-preloader"></div></div>`
      , 'text/xml').documentElement);

  });

}

export {swiper};