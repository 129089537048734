import {default as img} from '../assets/locImgPA.jpg';

const pa = `
<div class="swiper-slide" data-swiper-slide-index="1">
	<div class="locationDiv">
		<div class="header">
			<img src="${img}" class='headerImg' />
		</div>
		<div class="locationBody">
			<div class="locationName">Temple University Libraries</div>
			<div class="locationAddress">
			 	Special Collections Research Center <br>
				1900 N. 13th Street<br>
				Philadelphia, PA 19122
			</div>
			<div class="locationDetails">
				<a href="https://temple.aeon.atlas-sys.com/" target="_blank">Register</a><br>
			</div>
			<div class="locationTime">
				By Appointment, 8:30am–5:30pm daily
			</div>
		</div>
	</div>
</div>
`;

export {pa}